import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export default function useScript(path) {
    const [scriptRef, setScriptRef] = useState(null);

    const router = useRouter();

    useEffect(() => {
        const loadScript = (src) => {
            const script = document.createElement('script');
            script.src = src;
            script.async = false;
            document.body.appendChild(script);

            setScriptRef(script);
        };
    
        const removeScript = () => {            
            document.body.removeChild(scriptRef);

            setScriptRef(null);
        };

        if (typeof document !== undefined) {
            loadScript(path);
        }

        return () => {
            if (scriptRef) {
                removeScript();
            }
        }
    }, [router, path]);
}
