import {
  getAuth,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  createUserWithEmailAndPassword as firebaseCreateUserWithEmailAndPassword,
  sendEmailVerification as firebaseSendEmailVerification,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
  onAuthStateChanged as firebaseOnAuthStateChanged,
  signOut as firebaseSignOut,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signInWithCustomToken as firebaseSignInWithCustomToken,
  signInWithRedirect,
  getRedirectResult as firebaseGetRedirectResult,
} from 'firebase/auth';
import useFirebaseInit from './useFirebaseInit';

export default function useFirebaseAuth() {
  // Initialize Firebase
  useFirebaseInit();

  const auth = getAuth();

  const signInWithEmailAndPassword = (email, password) =>
    firebaseSignInWithEmailAndPassword(auth, email, password);

  const createUserWithEmailAndPassword = (email, password) =>
    firebaseCreateUserWithEmailAndPassword(auth, email, password);

  const sendEmailVerification = () =>
    firebaseSendEmailVerification(auth.currentUser);

  const sendPasswordResetEmail = (email) =>
    firebaseSendPasswordResetEmail(auth, email);
  
  const onAuthStateChanged = (callback) =>
    firebaseOnAuthStateChanged(auth, callback);

  const signOut = () =>
    firebaseSignOut(auth);

  const googleAuthProvider = new GoogleAuthProvider();

  const facebookAuthProvider = new FacebookAuthProvider();

  const appleAuthProvider = new OAuthProvider('apple.com');

  const googleAuthSignInWithPopup = () =>
    signInWithPopup(auth, googleAuthProvider);

  const facebookAuthSignInWithPopup = () =>
    signInWithPopup(auth, facebookAuthProvider);

  const appleAuthSignInWithPopup = () =>
    signInWithPopup(auth, appleAuthProvider);

  const googleAuthSignInWithRedirect = () =>
    signInWithRedirect(auth, googleAuthProvider);
  
  const facebookAuthSignInWithRedirect = () =>
    signInWithRedirect(auth, facebookAuthProvider);
  
  const appleAuthSignInWithRedirect = () =>
    signInWithRedirect(auth, appleAuthProvider);

  const getRedirectResult = () =>
    firebaseGetRedirectResult(auth);

  const signInWithCustomToken = (token) =>
    firebaseSignInWithCustomToken(auth, token);

  const getAccessToken = () =>
    auth?.currentUser?.accessToken;

  const getBearerToken = () =>
    `Bearer ${getAccessToken()}`;

  return {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    sendPasswordResetEmail,
    onAuthStateChanged,
    signOut,
    googleAuthSignInWithPopup,
    facebookAuthSignInWithPopup,
    appleAuthSignInWithPopup,
    signInWithCustomToken,
    googleAuthSignInWithRedirect,
    facebookAuthSignInWithRedirect,
    appleAuthSignInWithRedirect,
    getRedirectResult,
    getAccessToken,
    getBearerToken,
  };
}
