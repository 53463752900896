import { createContext, useContext, Context } from 'react'
import useFirebaseAuth from '../hooks/userFirebaseAuth';

const authUserContext = createContext({
  signInWithEmailAndPassword: async () => {},
  createUserWithEmailAndPassword: async () => {},
  sendEmailVerification: async () => {},
  sendPasswordResetEmail: async () => {},
  onAuthStateChanged: async () => {},
  signOut: async () => {},
  googleAuthSignInWithPopup: async () => {},
  facebookAuthSignInWithPopup: async () => {},
  appleAuthSignInWithPopup: async () => {},
  signInWithCustomToken: async () => {},
  googleAuthSignInWithRedirect: async () => {},
  facebookAuthSignInWithRedirect: async () => {},
  appleAuthSignInWithRedirect: async () => {},
  getRedirectResult: async () => {},
  getAccessToken: async () => {},
  getBearerToken: async () => {},
});

export function AuthUserProvider({ children }) {
  const auth = useFirebaseAuth();
  return <authUserContext.Provider value={auth}>{children}</authUserContext.Provider>;
}
// custom hook to use the authUserContext and access authUser and loading
export const useAuth = () => useContext(authUserContext);
