const termsOfService = `
    <h5 className="mt-5 mb-3">서비스 이용약관</h5>
    <h6 className="mt-5 mb-3">제 1 장 총칙</h6>

    <p className="mb-2">제 1 조 (목적)</p>
    <dd className="col-12">
    <p>
        주식회사 이대로(이하 ‘회사’)가 제공하는 시험 성적(모의고사, 대학수학능력시험) 분석 및 진단, 챗봇 입시 상담(AI 컨설팅)에 관한 온라인 서비스(이하 ‘서비스’)를 이용하면서 회원과 회사 간의 권리, 의무, 책임사항 및 기타 필요한 사항을 규정함을 목적으로 한다.
    </p>
    </dd>

    <p className="mb-2">제 2 조 (약관의 효력 및 변경)</p>
    <dd className="col-12">
    <p>① 이 약관은 이대로 앱, 홈페이지 화면(이하 "서비스 화면")에 게시하거나 기타의 방법으로 회원에게 공시되며, 이를 동의한 회원이 서비스에 가입함으로써 효력이 발생한다.</p>
    <p>② 회사는 필요하다고 인정되는 경우 이 약관의 내용을 변경할 수 있으며, 변경된 약관은 서비스 화면에 회원이 직접 확인할 수 있도록 공지한다.</p>
    <p>③ 회원은 변경된 약관에 동의하지 않으면 서비스 이용을 중단하거나 본인의 회원등록을 취소할 수 있으며, 계속 사용하는 경우에는 약관 변경에 동의한 것으로 간주하며 변경된 약관은 전항과 같은 방법으로 효력이 발생한다.</p>
    <p>④ 회원이 이 약관의 내용에 동의하는 경우 회사의 서비스 제공행위 및 회원의 서비스 이용행위에 이 약관이 먼저 적용된다. 이 약관에 명시되지 않은 사항에 대해서는 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법, 정보통신윤리위원회 심의 규정, 정보통신 윤리강령, 프로그램 보호법 등 기타 대한민국의 관련 법령과 상관습에 의한다.</p>
    </dd>

    <p className="mb-2">제 3 조 (약관 외 준칙)</p>
    <dd className="col-12">
    <p>
        본 약관에 규정되지 않은 사항에 대해서는 관련법령 또는 회사가 정한 운영정책 및 규칙 등(이하 ‘세부지침’)의 규정에 따릅니다.
    </p>
    </dd>

    <p className="mb-2">제 4 조 (용어의 정의)</p>
    <dd className="col-12">
    <p>① 이 약관에서 사용하는 용어의 정의는 다음과 같다.</p>
    <p>가. 회원: 서비스에 접속하여 회사에서 제공하는 서비스를 받는 사람으로, 본 약관에 동의하고 ID(아이디)와 PASSWORD(비밀번호)를 발급받은 자</p>
    <p>회원은 “학생회원”, “학부모회원”으로 구분된다.</p>
    <p>나. 학생회원: 회원 중 서비스를 제공받는 자(만 14세 이상만 학생회원으로 가입 가능)</p>
    <p>다. 학부모회원: 학생회원의 부모로서 자녀 대신 서비스를 이용하거나 자녀의 서비스 내용을 조회할 수 있는 법적 권리를 갖는 자(부모가 없는 경우 법정대리인으로 함)</p>
    <p>라. ID(아이디): 회원 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는 영문자, 혹은 영문자와 숫자, 혹은 영문자와 숫자와 특수문자의 조합 (1인 1의 ID만 발급 및 이용 가능)</p>
    <p>마. PASSWORD(비밀번호): 회원의 정보 보호를 위해 회원 자신이 설정한 영문자, 숫자, 특수문자의 조합</p>
    <p>바. 이용해지: 회원이 서비스에 등록된 개인정보의 제공을 철회하고 더 이상 서비스를 받는 것을 중단하는 행위</p>
    <p>사. 무료 서비스: 회사가 제공하는 서비스 중 유료 결제를 하지 않고 이용할 수 있는 모든 서비스. 회원만 이용 가능. 추후 추가/변동될 수 있으며, 추가/변동 시에는 서비스 화면에 변경사항을 공지</p>
    <p>아. 유료 서비스: 회사가 제공하는 서비스 중 유료 결제를 통해 이용할 수 있는 모든 서비스. 회원만 이용 가능. 서비스는 추후 추가/변동될 수 있으며, 추가/변동 시에는 서비스 화면에 변경사항을 공지</p>
    <p>② 이 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는 관계 법령에서 정하는 바에 의한다.</p>
    </dd>

    <h6 className="mt-5 mb-3">제 2 장 서비스 이용계약</h6>

    <p className="mb-2">제 5 조 (이용계약의 성립)</p>
    <dd className="col-12">
    <p>① 이용신청은 회원가입 화면에서 회원이 서비스에서 요구하는 가입신청 양식에 필요한 정보를 입력 또는 등록하는 방식으로 신청한다.</p>
    <p>② 서비스 이용계약은 사용자가 본 약관의 내용에 동의한 후 본 조 제1항에서 정한 이용신청을 하면 회사가 입력된 일정 정보를 인증한 후 가입을 승낙함으로써 체결됩니다.</p>
    </dd>

    <p className="mb-2">제 6 조 (이용신청의 승낙)</p>
    <dd className="col-12">
    <p>① 회사는 제5조에서 정한 사항을 정확히 기재하여 이용신청을 하였을 경우 특별한 사정이 없으면 서비스 이용신청을 즉시 승낙한다.</p>
    <p>② 단, 회사는 다음 각호에 해당하는 경우 회원의 이용신청 승낙을 유보 또는 제한할 수 있다.</p>
    <p>가. 본인의 실명으로 신청하지 않았을 때</p>
    <p>나. 다른 사람의 명의를 사용하여 신청하였을 때</p>
    <p>다. 신청서의 내용을 허위로 기재하였을 때</p>
    <p>라. 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청하였을 때</p>
    <p>마. 기타 회사가 정한 이용신청 요건이 미비 되었을 때</p>
    <p>바. 회사가 본 약관 또는 세부지침에 의해 회원의 계정을 삭제하였을 때</p>
    <p>사. 기타 회사가 필요하다고 인정되는 경우</p>
    <p>아. 기타 관련법령에 위배되거나 세부지침 등 회사가 정한 기준에 반하는 경우</p>
    <p>③ 만약, 회원이 제 2 항 각 호에 위반하여 계정을 만든 것으로 판명된 때에는 회사는 즉시 해당 회원의 서비스 이용을 정지시키거나 계정을 삭제하는 등 적절한 제한을 할 수 있다.</p>
    </dd>

    <p className="mb-2">제 7 조 (계약사항의 변경)</p>
    <dd className="col-12">
    <p>① 회원은 회원정보 관리를 통해 언제든지 자신의 정보를 열람하고 수정할 수 있다.</p>
    <p>② 회원은 이용신청 시 기재한 사항이 변경되었으면 수정을 하여야 하며, 수정하지 아니하여 발생하는 문제의 책임은 회원에게 있다.</p>
    </dd>

    <p className="mb-2">제 8 조 (계약해지)</p>
    <dd className="col-12">
        <p>① 회원이 이용계약을 해지하고자 할 때는 회원 본인이 서비스 페이지에 접속하여 해지 신청을 하여야 한다.</p>
        <p>② 이용계약이 해지되면 법령 및 개인정보 처리방침에 따라 회원의 정보를 보유하는 경우를 제외하고는 회원의 정보 및 서비스 데이터가 삭제된다. 다만, 회원이 서비스 내에서 작성한 게시물 등 모든 데이터의 삭제와 관련한 사항은 개별 서비스의 약관에 따른다.</p>
        <p>③ 이용계약이 해지된 경우라도 다시 회사에 대하여 이용계약의 체결을 신청할 수 있다.</p>
        <p>가. 단, 재가입은 6개월 이내 불가능하다.</p>
        <p>나. 6 개월 이내 재가입 희망 시 고객센터, 이메일을 통해 상담을 요청할 수 있다.</p>
    </dd>

    <h6 className="mt-5 mb-3">제 3 장 서비스 제공 및 이용</h6>

    <p className="mb-2">제 9 조 (서비스 이용)</p>
    <dd className="col-12">
    <p>① 회사는 회원의 이용신청을 승낙한 때부터 서비스를 개시한다. 단, 일부 서비스의 경우에는 지정된 일자부터 서비스를 개시한다.</p>
    <p>② 회사의 업무상 또는 기술상의 장애로 인하여 서비스를 개시하지 못할 때는 회원에게 이를 지체 없이 홈페이지, 이메일, 문자 메시지로 통지한다.</p>
    <p>③ 서비스의 이용은 연중무휴 1일 24시간을 원칙으로 한다.</p>
    <p>④ 유료서비스는 회원의 구분(학생회원, 학부모회원)에 따라 제공되는 정보가 다를 수 있다.</p>
    </dd>

    <p className="mb-2">제 10 조 (서비스의 변경)</p>
    <dd className="col-12">
    <p>① 회사는 회원에게 더 나은 서비스를 제공하기 위하여 서비스를 일부 변경할 수 있다.</p>
    <p>② 회원은 회사가 변경하는 서비스에 따라야 하며, 만족하지 못하는 경우 추가 변경을 요청하거나 서비스를 해지할 수 있다.</p>
    </dd>

    <p className="mb-2">제 11 조 (서비스의 중지)</p>
    <dd className="col-12">
    <p>① 국가의 비상사태, 정전 등 회사의 관리범위를 벗어난 재해 또는 재난 등의 상황에 따라 서비스의 변경, 중지 및 정보의 손실이 발생한 경우 회사는 어떠한 책임도 지지 않는다.</p>
    <p>② 회사는 제1항의 상황이 발생하더라도 서비스의 정상적인 제공이 되거나 정상적인 서비스 제공까지의 시간을 단축하기 위하여 노력을 다한다.</p>
    <p>③ 서비스는 회사의 업무상 또는 기술상의 이유로 일시 중지될 수 있으며, 정기점검 등 운영상의 목적으로 회사가 사전 정하여 고지한 기간에는 서비스가 일시 중지될 수 있다.</p>
    <p>④ 회사는 회사의 사정으로 서비스를 영구적으로 중단하여야 할 경우 회원에게 반드시 고지한다.</p>
    </dd>

    <p className="mb-2">제 12 조 (정보의 저장과 사용)</p>
    <dd className="col-12">
    <p>① 회사는 회원이 입력 또는 등록한 정보를 안전하게 저장 및 처리할 책임을 진다.</p>
    <p>② 회원은 입력 또는 등록한 정보를 수시로 확인하여 최신정보로 유지할 책임을 진다.</p>
    <p>③ 회원의 개인정보 관련된 자세한 사항은 회사의 개인정보처리방침에 따른다.</p>
    </dd>

    <p className="mb-2">제 13 조 (서비스의 이용제한)</p>
    <dd className="col-12">
    <p>① 회사는 다음 각 호와 같은 회원의 행위를 발견한 경우 서비스 이용을 정지시키거나 계약을 해지하는 등 회원의 서비스 이용을 제한할 수 있다. 이 경우 회사는 회원에게 서비스 이용제한의 사유를 통지하여 알릴 수 있다. 단, 회원에 대한 서비스 중지에 대한 고지는 사후 진행될 수 있다.</p>
    <p>가. 서비스를 회사, 다른 회원 또는 제3자를 비방하거나 명예를 손상시키려는 목적으로 사용한 경우</p>
    <p>나. 자신이 아닌 다른 사람의 정보를 이용하여 서비스를 이용하는 경우</p>
    <p>다. 공공질서 및 미풍양속에 위반되는 경우</p>
    <p>라. 범죄적 행위에 결부된다고 인정되는 경우</p>
    <p>마. 제3자의 저작권 등 기타 권리를 침해하는 경우</p>
    <p>바. 서비스 성격에 부합하지 않는 정보를 입력 또는 등록하는 경우</p>
    <p>사. 기타 관계 법령 및 회사에서 정한 규정 등에 위배되는 경우</p>
    <p>② 회사는 회원의 정보처리시스템(스마트폰, PC 등)의 보안 취약점에 의하여 서비스 전체에 영향이 있다고 판단하는 경우 특정 회원의 서비스 사용을 중지할 수 있다. 이 경우 회원은 필요한 보안 요건을 만족하여야만 서비스의 계속 이용이 가능하다.</p>
    </dd>

    <p className="mb-2">제 14 조 (정보의 제공 및 광고의 게재)</p>
    <dd className="col-12">
    <p>① 회사는 회원의 서비스 이용에 필요하다고 인정되는 다양한 정보를 전자우편, 문자메시지, 앱 푸쉬 등 다양한 방법으로 회원에게 제공할 수 있다.</p>
    <p>② 회원은 제1항에도 불구하고 해당 정보를 원하지 않는 경우 수신 거부할 수 있다. 이 경우 회사는 정보 수신거부에 대한 기술적인 지원을 수행한다.</p>
    </dd>

    <p className="mb-2">제 15 조 (데이터의 소유권)</p>
    <dd className="col-12">
    <p>① 회사의 서비스에 따라 제공되는 시험 성적 분석 데이터, 온라인 학습 관련 데이터의 저작권은 회사에게 있으며, 회사는 이러한 데이터를 이용할 권한을 갖는다.</p>
    <p>② 회원이 회원가입 또는 회원정보 수정을 통하여 입력 또는 등록한 데이터(모의고사성적, 대학수학능력시험성적, 개인정보 등)의 소유권은 회원에게 있다. 다만, 회사가 이를 이용하여 식별 가능한 개인정보를 삭제한 후 가공하고, 이를 서비스 성능의 개선을 위해 데이터화 하였을 경우의 소유권은 회사에게 있다.</p>
    <p>③ 회원은 서비스 이용에 따라 얻은 데이터를 상업적으로 이용할 수 없다.</p>
    </dd>

    <p className="mb-2">제 16 조 (회사의 소유권)</p>
    <dd className="col-12">
    <p>① 회사가 제공하는 서비스, 그에 필요한 소프트웨어, 이미지, 마크, 로고, 디자인, 서비스명칭, 정보 및 상표 등과 관련된 지적재산권 및 기타권리는 회사가 소유한다.</p>
    <p>② 회원은 회사가 명시적으로 승인한 경우를 제외하고는 제 1 항 소정의 각 재산에 대한 전부 또는 일부의 수정 · 대여 · 대출 · 판매 · 배포 · 제작 · 양도 · 재라이선스 · 담보권 설정 · 상업적 이용행위를 할 수 없으며, 제 3 자로 하여금 이와 같은 행위를 하도록 허락할 수 없다.</p>
    </dd>

    <h6 className="mt-5 mb-3">제 4 장 서비스 요금 등</h6>

    <p className="mb-2">제 17 조 (요금 등의 종류)</p>
    <dd className="col-12">
    <p>① 회원이 납입하여야 하는 요금의 종류는 다음 각 호와 같다.</p>
    <p>가. 서비스 요금 : 1회성 서비스 이용에 따라 납입하여야 하는 요금</p>
    </dd>

    <p className="mb-2">제 18 조 (요금의 납입 등)</p>
    <dd className="col-12">
    <p>① 요금의 납입은 인앱 결제, 신용카드 결제, 무통장 입금 등 회사가 제시한 방법 중 회원이 선택한 방법으로 한다. 단, 회원이 기존 요금의 납입 방법을 변경하고자 하는 경우 회사와 협의 또는 직접 스토어에서 변경해야 한다.</p>
    <p>② 회사는 회원에게 회사가 지정하는 기일에 요금 등을 납입하도록 청구한다.</p>
    <p>③ 회사는 회원이 요금을 납입하지 않은 경우 서비스를 중지할 수 있다.</p>
    <p>④ 회사는 회원에게 책임이 없는 사유로 인하여 서비스를 제공받지 못한 경우로서 회원이 그 뜻을 회사에 통지한 경우 요금을 반환할 수 있다. 단, 반환되는 요금은 당 월 또는 당 회에 한하며, 홈페이지에 공지한 해당 상품의 환불정책에 따라 처리 된다.</p>
    <p>가. 인앱결제 - AppStore : 스토어 환불 정책을 적용</p>
    <p>나. 인앱결제 - GooglePlay : 스토어 환불 정책 적용</p>
    <p>다. 카드결제, 무통장 입금 - 결제 후 14 일 이내 : 미사용한 이용권 환불 가능</p>
    <p>라. 카드결제, 무통장 입금 - 결제 후 14 일 경과 : 환불불가</p>
    <p>⑤ 환불에 관련된 자세한 사항은 회사의 환불처리정책방침에 따른다.</p>
    </dd>

    <h6 className="mt-5 mb-3">제 5 장 계약 당사자의 의무</h6>

    <p className="mb-2">제 19 조 (회원의 의무)</p>
    <dd className="col-12">
    <p>① 회원은 서비스 이용을 위해 가입 시 현재의 사실과 일치하는 완전한 정보(이하 "가입정보"라 한다)를 제공해야 한다. 또한 가입정보가 변경된 경우 즉시 갱신해야 한다.</p>
    <p>② 회원은 자신이 설정한 ID 및 PASSWORD를 안전하게 관리하여야 하며 ID 및 PASSWORD 관리를 위하여 다음 각 호와 같은 행위를 수행할 의무가 있다.</p>
    <p>가. 회원의 승인 없이 ID, PASSWORD가 사용되는 문제가 발생한 경우 즉시 회사에 신고 하여야 한다.</p>
    <p>나. 회사가 권고하거나 회원이 필요하다고 판단한 경우 주기적인 비밀번호의 변경</p>
    <p>다. 매 접속 종료 시 확실한 로그아웃</p>
    <p>③ 이 약관에 동의한 회원은 서비스 이용 중 다음과 같은 행위를 하지 않는 것에 동의한 것으로 간주한다.</p>
    <p>가. 1인 이상의 타인에게 위해를 가하는 행위</p>
    <p>(1) 타인의 ID, PASSWORD, 주민등록번호 도용 및 타인으로 가장하는 행위</p>
    <p>(2) 타인이 서비스 이용으로 얻은 정보를 정상적인 서비스 이용이 아닌 방법으로 획득하는 경우</p>
    <p>(3) 서비스의 결과물을 이용하여 타인에게 손해를 가하거나 그와 유사한 목적으로 서비스를 이용하는 경우</p>
    <p>(4) 타인을 비방할 목적으로 사실 또는 허위의 사실을 적시하여 명예를 훼손하는 행위</p>
    <p>(5) 본인 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가할 목적으로 서비스 관련 정보 또는 허위사실을 유통시키는 행위</p>
    <p>(6) 회사의 사전 승낙 없이 서비스를 이용한 영리행위</p>
    <p>나. 서비스의 기본 기능을 우회하거나 기술적 취약점을 이용하여 공격하는 행위</p>
    <p>다. 권리(지적재산권을 포함한 모든 권리)가 없는 컨텐츠를 게시, 게재 또는 전자우편으로 보내는 행위</p>
    <p>라. 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비를 파괴, 방해 또는 기능을 제한하기 위한 소프트웨어 바이러스를 게시, 게재 또는 전자우편으로 보내는 행위</p>
    <p>마. 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시, 게재, 전자우편으로 보내는 행위 등 다른 사용자의 개인정보를 수집 또는 저장하는 행위</p>
    <p>바. 기타 불법적이거나 부당한 행위</p>
    <p>④ 회원은 이 약관 및 관계법령에서 규정한 사항을 준수해야 한다.</p>
    </dd>

    <p className="mb-2">제 20 조 (회사의 의무)</p>
    <dd className="col-12">
    <p>① 회사는 특별한 사정이 없는 한 회원이 신청한 서비스 제공 개시일에 서비스를 이용할 수 있도록 한다.</p>
    <p>② 회사는 이 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 의무가 있다.</p>
    <p>③ 회사가 제공하는 서비스로 인하여 회원에게 손해가 발생한 경우 회사의 고의 또는 중과실에 의한 경우에 한하여 회사가 책임을 부담하며, 그 책임의 범위는 통상손해로 제한한다. 이 경우 회사의 고의 또는 중과실이 있었다는 사실의 입증 책임은 회원에게 있다.</p>
    <p>④ 회사는 제3항에 대하여 책임을 부담하지 않으려는 경우 회사의 고의 또는 중과실이 없었다는 사실을 입증하여야 한다.</p>
    <p>⑤ 회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 신속히 처리해야 한다. 다만, 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 통보해야 한다.</p>
    <p>⑥ 회사는 개인정보보호법 등 관련 법령이 정하는 바에 따라서 회원 등록정보를 포함한 회원의 개인정보를 보호하기 위하여 노력한다.</p>
    </dd>

    <p className="mb-2">제 21 조 (개인정보보호정책)</p>
    <dd className="col-12">
    <p>① 회사는 회원이 제공하는 등록정보 및 개인정보에 관한 정책을 수립하여 “개인정보 처리방침”으로 회원에게 고지한다.</p>
    <p>② 회사가 고지하는 “개인정보 처리방침”은 버전 별 적용일자부터 적용하며, 소급 적용할 수 없다.</p>
    <p>③ 회사 및 회원은 개인정보보호를 위한 최선의 노력을 다하여야 한다.</p>
    </dd>

    <h6 className="mt-5 mb-3">제 6 장 기타</h6>

    <p className="mb-2">제 22 조 (양도금지)</p>
    <dd className="col-12">
    <p>회원은 서비스의 이용권한, 기타 이용 계약상의 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없다.</p>
    </dd>

    <p className="mb-2">제 23 조 (손해배상)</p>
    <dd className="col-12">
    <p>① 회사는 법령상 허용되는 한도 내에서 서비스와 관련하여 본 약관에 명시되지 않은 어떠한 구체적인 사항에 대한 약정이나 보증을 하지 않습니다. 또한, 회사는 CP(Contents Provider)가 제공하거나 회원이 작성하는 등의 방법으로 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며, 회사의 과실 없이 발생된 여러분의 손해에 대하여는 책임을 부담하지 아니합니다.</p>
    <p>② 회사는 회사의 과실로 인하여 회원이 손해를 입게 될 경우 본 약관 및 관련 법령에 따라 회원의 손해를 배상하겠습니다. 다만, 회사는 회사의 과실 없이 발생된 아래와 같은 손해에 대해서는 책임을 부담하지 않습니다. 또한, 회사는 법률상 허용되는 한도 내에서 간접 손해, 특별 손해, 결과적 손해, 징계적 손해, 및 징벌적 손해에 대한 책임을 부담하지 않습니다.</p>
    <p>가. 천재지변 또는 이에 준하는 불가항력의 상태에서 발생한 손해</p>
    <p>나. 회원의 귀책사유로 서비스 이용에 장애가 발생한 경우</p>
    <p>다. 서비스에 접속 또는 이용과정에서 발생하는 개인적인 손해</p>
    <p>라. 제 3 자가 불법적으로 회사의 서버에 접속하거나 서버를 이용함으로써 발생하는 손해</p>
    <p>마. 제 3 자가 회사 서버에 대한 전송 또는 회사 서버로부터의 전송을 방해함으로써 발생하는 손해</p>
    <p>바. 제 3 자가 악성 프로그램을 전송 또는 유포함으로써 발생하는 손해</p>
    <p>사. 전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해, 명예훼손 등 제 3 자가 서비스를 이용하는 과정에서 발생된 손해</p>
    <p>아. 기타 회사의 고의 또는 과실이 없는 사유로 인해 발생한 손해</p>
    </dd>

    <p className="mb-2">제 24 조 (면책조항)</p>
    <dd className="col-12">
    <p>① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제된다.</p>
    <p>② 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않는다.</p>
    <p>③ 회사는 회원이 서비스를 이용하여 기대하는 이익이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않는다.</p>
    </dd>

    <p className="mb-2">제 25 조 (관할법원)</p>
    <dd className="col-12">
    <p>① 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우, 회사와 회원은 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 기울여야 한다.</p>
    <p>② 제 1 항의 규정에도 불구하고 서비스 이용으로 발생한 분쟁에 대하여 소송이 제기될 경우 회사 소재지를 관할하는 법원을 관할법원으로 한다.</p>

`;

const privacyPolicy = `
    <h5 className="mt-5 mb-3">개인정보 처리방침</h5>
    <h6 className="mt-5 mb-3">제 1 장 이대로 개인정보 처리방침</h6>
    <p className="mb-4">
    “개인정보 처리방침”이란 이용자가 안심하고 서비스를 이용할 수 있도록 회사가 준수해야 할 지침을 의미하며, 이대로는 개인정보처리자가 준수하여야 하는 대한민국의 관계법령 및 개인정보보호 규정, 가이드라인을 준수하여 개인정보 처리방침을 제공합니다.
    </p>

    <p className="mb-2">1. 개인정보</p>
    <dd className="col-12">
    <p>가. 필수사항 : 이메일, 비밀번호</p>
    <p>나. 선택사항 : 생년월일, 성별, 전화번호, 고등학교</p>
    </dd>

    <p className="mb-2">2. 처리목적</p>
    <dd className="col-12">
    <p>
        이대로에서 수집한 이용자 개인정보는 회원관리, 서비스 제공 및 개선, 신규 서비스 개발 등 이대로 서비스 제공을 위해 필요한 목적으로만 이용합니다. 개인정보 수집 시 반드시 이용자에게 처리 목적을 고지하여 동의를 받고 있습니다.
    </p>
    </dd>

    <p className="mb-2">3. 제3자 제공</p>
    <dd className="col-12">
    <p>
        제3자의 서비스 연결 및 제휴사 서비스 이용을 위해 개인정보가 제공될 수 있습니다. 이대로는 법령에 규정된 경우를 제외하고는 이용자의 별도 동의없이 제3자에게 개인정보를 제공하지 않습니다. 제3자 제공 발생 시 이용자에게 제공받는 자, 제공목적 등을 고지하여 동의를 받은 후 제공합니다.
    </p>
    </dd>

    <p className="mb-2">4. 처리위탁</p>
    <dd className="col-12">
    <p>
        서비스 제공을 위해 필요한 경우 개인정보 처리 업무 중 일부를 외부에 위탁할 수 있습니다. 위탁받은 업체가 위탁받은 업무 목적 외로 개인정보를 처리하는 것을 제한하고, 기술적/관리적 보호조치 적용 및 재위탁 제한 등 위탁업체의 개인정보 보호 관련 법령 준수 여부를 관리/감독하고 있습니다.
    </p>
    </dd>

    <p className="mb-2">5. 정보주체의 권리의무</p>
    <dd className="col-12">
    <p>
        이대로는 개인정보 처리에 대한 정보주체의 권리 의무를 보장합니다. 이대로 이용자는 언제든지 본인의 개인정보를 조회하거나 수정할 수 있으며, 수집/이용, 제공에 대한 동의를 철회하거나 가입 해지를 요청할 수 있습니다.
    </p>
    </dd>

    <p className="mb-2">6. 고충처리</p>
    <dd className="col-12">
    <p>
        이대로 서비스 이용 중 발생하는 개인정보보호 관련 문의, 불만, 조언이나 기타 사항은 이대로의 개인정보보호책임자 및 담당부서로 연락주시면 확인하여 답변드리고 있습니다. 이대로는 항상 사용자의 목소리에 귀기울이고 더 안전한 서비스를 제공하기 위해 노력하고 있습니다.
    </p>
    </dd>

    <h6 className="mt-5 mb-3">제 2 장 개인정보 수집</h6>
    <p className="mb-2">1. 서비스 제공을 위한 필요 최소한의 개인정보를 수집합니다.</p>
    <dd className="col-12">
    <p>
        회원 가입 시 또는 서비스 이용 과정에서 홈페이지 또는 어플리케이션이나 프로그램 등을 통해 서비스 제공을 위해 필요 최소한의 개인정보를 수집하고 있습니다. 서비스 제공을 위해 반드시 필요한 최소한의 정보를 필수항목으로, 그 외 특화된 서비스를 제공하기 위해 추가 수집하는 정보는 선택항목으로 동의를 받고 있으며, 선택항목에 동의하지 않은 경우에도 서비스 이용 제한은 없습니다.
    </p>
    <p>
        가. 회원가입 시에는 이메일, 비밀번호는 필수입력 사항이며, 상품 결제 시 생년월일, 성별, 전화번호는 선택입력 정보입니다.
    </p>
    <p>
        나. 본인인증 시에는 이름, 성별, 생년월일, 휴대전화번호, 통신사업자, 내/외국인 여부, 암호화된 이용자 확인값(CI), 중복가입확인정보(DI)가 필요합니다.
    </p>
    <p>
        다. 법정대리인 동의 시에는 법정대리인 정보(이름, 성별, 생년월일, 휴대전화번호, 통신사업자, 내/외국인 여부, 암호화된 이용자 확인값(CI), 중복가입확인정보(DI))가 필요합니다.
    </p>
    <p>
        라. 유료서비스 이용 시:
        <br />
        - 신용카드 결제 시: 카드번호(일부), 카드사명 등
        <br />
        - 휴대전화번호 결제 시: 휴대전화번호, 결제승인번호 등
        <br />
        - 계좌이체 시: 예금주명, 계좌번호, 계좌은행 등
        <br />
        - 상품권 이용 시: 상품권 번호, 해당 사이트 아이디
    </p>
    <p>
        마. 환불처리 시에는 계좌은행, 계좌번호, 예금주명, 이메일의 정보가 필요합니다.
    </p>
    <p>
        바. 현금영수증 발행 시에는 휴대전화번호, 현금영수증 카드번호의 정보가 필요합니다.
    </p>
    <p>
        사. 고객상담 시에는 고객담당 부서로 문의 및 상담 처리를 위한 추가적인 정보를 수집할 수 있습니다.
    </p>
    </dd>
    <br/>
    <p className="mb-2">2. 일부 서비스에서는 특화된 여러 기능을 제공하기 위해 이용자에게 동의를 받고 추가적인 개인정보를 수집할 수 있습니다.</p>
    <br/>
    <p className="mb-2">3. 개인정보를 수집하는 경우에는 원칙적으로 사전에 이용자에게 해당사실을 알리고 동의를 구하고 있으며, 아래와 같은 방법을 통해 개인정보를 수집합니다.</p>
    <dd className="col-12">
    <p>가. 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우</p>
    <p>나. 제휴 서비스 또는 단체 등으로부터 개인정보를 제공받은 경우</p>
    <p>다. 고객센터를 통한 상담 과정에서 웹페이지, 메일, 팩스, 전화 등</p>
    <p>라. 온·오프라인에서 진행되는 이벤트/행사 등 참여</p>
    </dd>
    <br/>
    <p className="mb-2">4. 서비스 이용 과정에서 단말기정보, IP 주소, 쿠키, 서비스 이용내역 등의 정보가 자동으로 생성되어 수집될 수 있습니다.</p>
    <br/>
    <p className="mb-2">5. 서비스 간 제휴, 연계 등으로 제 3 자로부터 개인정보를 제공받습니다</p>
    
    <h6 className="mt-5 mb-3">제 3 장 개인정보 이용</h6>
    <p className="mb-2">1. 회원관리, 서비스 제공·개선, 신규서비스 개발 등을 위해 이용합니다.</p>
    <dd className="col-12">
    <p>가. 회원 식별 및 가입의사 확인, 본인/연령 확인</p>
    <p>나. 14세 미만 아동의 개인정보 수집 시 법정 대리인 동의여부 확인, 법정 대리인 권리행사 시 본인 확인</p>
    <p>다. 문의사항 또는 불만처리, 공지사항 전달</p>
    <p>라. 유료서비스 이용 시 콘텐츠 등의 전송이나 배송·요금 정산</p>
    <p>마. 서비스의 원활한 운영에 지장을 주는 행위(계정 도용 및 부정 이용 행위 등 포함)에 대한 방지 및 제재</p>
    <p>바. 음성명령 처리 및 음성인식 향상</p>
    <p>사. 신규 서비스 개발 및 서비스 기능 개선, 개인화된 서비스 제공, 프라이버시 보호를 위한 서비스 환경 구축</p>
    <p>아. 서비스 이용 기록, 접속 빈도 및 서비스 이용에 대한 통계</p>
    </dd>

    <p className="mb-2">2. 개인정보의 추가적인 이용·제공을 하는 경우가 있습니다.</p>
    <dd className="col-12">
    <p>
        수집 목적과 합리적으로 관련된 범위에서는 법령에 따라 이용자의 동의 없이 개인정보를 이용하거나 제3자에게 제공할 수 있습니다. 이때 ‘당초 수집 목적과 관련성이 있는지, 수집한 정황 또는 처리 관행에 비추어 볼 때 개인정보의 추가적인 이용 또는 제공에 대한 예측 가능성이 있는지, 이용자의 이익을 부당하게 침해하는지, 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지’를 종합적으로 고려합니다.
    </p>
    <p>
        이대로는 수집한 개인정보를 특정 개인을 알아볼 수 없도록 가명 처리하여 통계작성, 과학적 연구, 공익적 기록보존 등을 위하여 처리할 수 있습니다. 이때 가명정보는 재식별되지 않도록 추가정보와 분리하여 별도 저장·관리하고 필요한 기술적·관리적 보호조치를 취합니다.
    </p>
    </dd>

    <h6 className="mt-5 mb-3">제 4 장 개인정보 제공</h6>
    <p className="mb-2">1. 이대로는 이용자의 사전 동의 없이 개인정보를 제 3 자에게 제공하지 않습니다. 다만, 이용자가 이대로 계정 로그인 서비스를 이용하거나 외부 제휴사 등의 서비스를 이용하는 경우 필요한 범위 내에서 이용자의 동의를 얻은 후에 개인정보를 제 3 자에게 제공하고 있습니다. 또한, 해외 서비스 이용을 위해 이용자의 동의를 받아 국외로 개인정보가 제공될 수 있으며, 국외 이전 시 제공받는 자 및 이전 국가 등에 대해 고지하여 별도 동의를 받고 있습니다. 이대로는 재난, 감염병, 급박한 생명·신체 위험을 초래하는 사건사고, 급박한 재산 손실 등의 긴급상황이 발생하는 경우 정보주체의 동의 없이 관계기관에 개인정보를 제공할 수 있습니다.
    </p>

    <p className="mb-2">2. 서비스 제공을 위해 필요한 경우 개인정보 처리 업무 중 일부를 외부에 위탁할 수 있습니다. 위탁받은 업체가 위탁받은 업무 목적 외로 개인정보를 처리하는 것을 제한하고, 기술적·관리적 보호조치 적용 및 재위탁 제한 등 위탁받은 업체의 개인정보 보호 관련 법령 준수 여부를 관리·감독하고 있습니다. 이용자는 고객센터를 통해 개인정보의 국외 이전을 거부할 수 있습니다. 이용자가 개인정보의 국외 이전을 거부하는 경우, 개인정보 국외이전이 필수적으로 수반되는 서비스의 이용이 제한될 수 있습니다.
    </p>

    <h6 className="mt-5 mb-3">제 5 장 개인정보 파기</h6>
    <p className="mb-2">1. 수집 및 이용 목적의 달성 또는 회원 탈퇴 등 파기 사유가 발생한 경우 개인정보의 형태를 고려하여 파기방법을 정합니다.</p>
    <dd className="col-12">
    <p>
        전자적 파일 형태인 경우 복구 및 재생되지 않도록 안전하게 삭제하고, 그 밖에 기록물, 인쇄물, 서면 등의 경우 분쇄하거나 소각하여 파기합니다.
    </p>
    </dd>

    <p className="mb-2">2. 내부 방침에 따라 일정 기간 보관 후 파기하는 정보는 아래와 같습니다.</p>
    <dd className="col-12">
    <p>가. 아래 정보는 탈퇴일부터 최대 1 년간 보관 후 파기합니다.</p>
    <p>
        - 안내메일 발송 및 CS 문의 대응을 위해 계정과 탈퇴안내 이메일 주소를 암호화하여 보관
        <br />
        - 서비스 부정이용 기록
    </p>
    <p>나. 권리침해 신고 및 유해정보 신고 이력은 5 년간 보관 후 파기합니다.</p>
    <p>
        - 카드영수증: 카드영수증 서비스 탈퇴일부터 1 년 보관 후 파기합니다.
        <br />
        - 암호화된 이용자 확인값(CI), 카드영수증 항목 및 상세내역
    </p>
    </dd>
    <p className="mb-2">3. 이대로는 운영정책에 따라 1 년간 서비스를 이용하지 않은 이용자의 개인정보를 별도로 분리 보관 또는 삭제하고 있으며, 분리 보관된 개인정보는 4 년간 보관 후 지체없이 파기합니다.</p>

    <h6 className="mt-5 mb-3">제 6 장 이용자 및 법정대리인의 권리와 행사 방법</h6>
    <p className="mb-2">1. 이용자는 자신의 개인정보 처리에 관하여 아래와 같은 권리를 가질 수 있습니다.</p>
    <dd className="col-12">
    <p>가. 개인정보 열람(조회)을 요구할 권리</p>
    <p>나. 개인정보 정정을 요구할 권리</p>
    <p>다. 개인정보 처리정지를 요구할 권리</p>
    <p>라. 개인정보 삭제요구 및 동의철회/탈퇴를 요구할 권리</p>
    </dd>

    <p className="mb-2">
    2. 이대로는 이용자의 요청을 받은 경우 이를 지체없이 처리하며, 이용자가 개인정보의 오류에 대한 정정을 요청한 경우 정정을 완료하기 전까지 해당 개인정보를 이용 또는 제공하지 않습니다.
    </p>

    <p className="mb-2">3. 14세 미만 아동의 개인정보를 처리할 경우에는 법정대리인의 동의를 받아야 합니다.</p>
    <dd className="col-12">
    <p>
        법정대리인은 아동의 개인정보를 조회하거나 수정 및 삭제, 처리정지 및 동의 철회 등의 권리를 행사할 수 있습니다.
    </p>
    </dd>

    <p className="mb-2">
    4. 법정대리인의 동의를 받기 위하여 아동에게 법정대리인의 서명, 연락처와 같이 최소한의 정보를 요구할 수 있으며, 아래 방법으로 법정대리인의 동의를 확인합니다.
    </p>
    <dd className="col-12">
    <p>가. 법정대리인의 휴대전화 본인인증을 통해 본인여부를 확인하는 방법</p>
    <p>나. 법정대리인에게 동의내용이 적힌 서면을 제공하여 서명날인 후 제출하게 하는 방법</p>
    <p>다. 그 밖에 위와 준하는 방법으로 법정대리인에게 동의내용을 알리고 동의의 의사표시를 확인하는 방법</p>
    </dd>

    <h6 className="mt-5 mb-3">제 7 장 개인정보 자동 수집 장치에 관한 사항(설치·운영 및 거부)</h6>
    <p className="mb-2">
        1. 웹기반 서비스 제공을 위하여 쿠키를 설치·운영할 수 있습니다. 빠르고 편리한 웹사이트 사용을 지원하고 맞춤형 서비스를 제공하기 위해 쿠키를 사용합니다. 
    </p>

    <p className="mb-2">
        2. 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 쿠키를 사용합니다. 이용자가 웹사이트에 방문할 경우 웹 사이트 서버는 이용자의 디바이스에 저장되어 있는 쿠키를 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공하게 됩니다. 쿠키는 이용자가 웹 사이트를 방문할 때, 웹 사이트 사용을 설정한대로 접속하고 편리하게 사용할 수 있도록 돕습니다. 또한, 이용자의 웹사이트 방문 기록, 이용 형태를 통해서 최적화된 광고 등 맞춤형 정보를 제공하기 위해 활용됩니다.
    </p>

    <p className="mb-2">
        3. 이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 웹브라우저 상단의 ‘설정 > 개인정보보호 > 쿠키 및 기타 사이트 데이터’ 경로에서 쿠키 설정을 통해 쿠키 허용 및 거부를 할 수 있습니다. 다만, 쿠키 설치를 거부할 경우 웹 사용이 불편해지며, 로그인이 필요한 일부 서비스 이용이 어려울 수 있습니다.
    </p>


    <h6 className="mt-5 mb-3">제 8 장 개인정보 안정성 확보 조치에 관한 사항</h6>
    <p className="mb-2">이대로는 이용자의 개인정보 보호를 위해 아래의 노력을 합니다.</p>
    <dd className="col-12">
    <p>1. 이용자의 개인정보를 암호화하고 있습니다.</p>
    <p>2. 해킹이나 컴퓨터 바이러스로부터 보호하기 위하여 노력하고 있습니다.</p>
    <p>3. 개인정보에 접근할 수 있는 사람을 최소화하고 있습니다.</p>
    <p>4. 개인정보 취급자에게 이용자의 개인정보 보호에 대해 정기적인 교육을 실시하고 있습니다.</p>
    <p>5. 이용자 정보의 보호 활동 및 체계에 대해 국내 인증 규격을 충족하고 있습니다.</p>
    </dd>

    <h6 className="mt-5 mb-3">제 9 장 개인정보 보호책임자 및 고충처리 부서</h6>
    <p className="mb-2">이대로는 이용자의 개인정보 관련 문의사항 및 불만처리 등을 위하여 개인정보 보호책임자 및 고충처리 부서를 운영하고 있습니다.</p>
    <dd className="col-12">
    <p>1. 책임자 : 김선국(개인정보 보호책임자/대표이사)</p>
    <p>2. 소속 : 개인정보보호부서</p>
    <p>3. 문의 : ceo@edaero.kr</p>
    </dd>

`;

const marketingAgreement = `
    <h5 className="mt-5 mb-3">광고 및 마케팅 수신 동의</h5>
    <p className="mb-2">
        ㈜이대로(이하 ‘회사’라 함)는 개인정보 보호법 제 22 조 제 4 항과 제 39 조의 3 에 따라 사용자의 광고성 정보 수신과 이에 따른 개인정보 처리에 대한 동의를 받고 있습니다. 약관에 동의하지 않으셔도 이대로의 서비스를 이용하실 수 있습니다. 다만, 이벤트, 혜택 등의 제한이 있을 수 있습니다.
    </p>
    <br/>
    <p className="mb-2">1.  ㈜이대로(이하 ‘회사’라 함)의 “이대로” 어플리케이션 서비스에 포함되어 있는 앱 푸시, 채팅, 이메일 등을 통해서 서비스가 제공하는 서비스 소식 및 마케팅 정보를 수신합니다.</p>
    <p className="mb-2">2. “이대로” 어플리케이션의 앱 푸시, 이메일은 회사가 직접 운영하는 통합서비스들의 소식과 혜택, 쿠폰 등 유용한 정보를 제공하는 채널 입니다.</p>
    <p className="mb-2">3. “이대로” 어플리케이션의 마케팅 정보를 수신하고 싶지 않은 경우, 언제든지 마이페이지 > 설정 > 알림 에서 수신을 해제할 수 있습니다.</p>
    <dd className="col-12">
    <p className="mb-2">가. 목적 신규 서비스(제품) 안내, 이벤트 참여기회 제공, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등 마케팅 활용 목적으로 이용자 정보(이메일 등)와 ‘쿠키’ 또는 ‘광고 식별자’ 기반으로 수집된 행태 정보를 활용할 수 있으며 서비스 및 마케팅 정보는 이메일, 앱 푸시, 등의 채널로 발송됩니다. 또한, 콘텐츠 등 기존 서비스 제공(광고 포함)에 더하여, 서비스 방문 및 이용기록의 분석,개인정보 및 관심에 기반한 이벤트 참여기회 제공 등 신규 서비스(제품) 요소의 발굴 및 기존 서비스 개선 등을 위하여 개인정보를 이용합니다.</p>
    </dd>
    <dd className="col-12">
    나. 항목 보유 및 이용기간 동의철회, 회원 탈퇴시 또는 본 내용에 동의를 거부하시는 경우에도 “이대로” 어플리케이션의 서비스는 이용이 가능합니다. 다만, “이대로”어플리케이션의 신규 서비스(제품) 안내, 이벤트 참여 안내 등 “이대로” 어플리케이션의 이용에 도움이 되는 정보를 수신할 수 없습니다.
    </dd>
`;

const refundPoicy = `
    <h6 className="mt-5 mb-3">제 1 장 총칙</h6>

    <p className="mb-2">제 1 조 (목적)</p>
    <dd className="col-12">
    <p>
        주식회사 이대로(이하 ‘회사’)는 신뢰있는 서비스를 제공할 수 있도록 소비자 기본법에서 정하고 있는 소비자 분쟁 해결기준을 참고하여 환불 정책을 시행하고 있습니다. 따라서, 구매자는 본 정책에서 안내하는 사유를 이유로 환불을 신청할 수 있고, 회사는 본 정책에서 안내하는 사유를 이유로 환불을 할 수 있습니다.
    </p>
    </dd>

    <h6 className="mt-5 mb-3">제 2 장 서비스 이용계약</h6>

    <p className="mb-2">제 2 조 (구매신청)</p>
    <dd className="col-12">
    <p>
        구매자는 회사의 어플리케이션 서비스상에서 다음 또는 이와 유사한 방법에 의하여 재화, 서비스의 구매를 신청하며, 회사는 구매자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
    </p>
    <p>① 유료상품의 제공 내역과 이용범위</p>
    <p>② 청약 철회권이 제한되는 서비스에 대한 확인</p>
    <p>③ 유료상품 구매 시 주의사항에 대한 동의</p>
    <p>④ 결제방법의 선택</p>
    </dd>

    <p className="mb-2">제 3 조 (구매 계약의 성립)</p>
    <dd className="col-12">
    <p>① 회사는 제2조와 같은 구매신청에 대하여 신청 내용에 허위, 기재누락, 오기가 있을 경우 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있습니다.</p>
    <p>② 회사의 승낙이 구매자에게 수신 확인 통지 형태로 도달한 시점에 계약이 성립한 것으로 봅니다.</p>
    </dd>

    <p className="mb-2">제 4 조 (대금의 지급)</p>
    <dd className="col-12">
    <p>
        회사에서 구매한 재화 또는 서비스에 대한 대금지급방법은 아래의 방법으로 할 수 있습니다. 단, 회사는 구매자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다.
    </p>
    <p>① 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체</p>
    <p>② 선불카드, 직불카드, 신용카드 등의 각종 카드결제</p>
    <p>③ 온라인 무통장 입금</p>
    <p>④ 회사와 계약을 맺었거나 회사가 인정한 상품권에 의한 결제</p>
    <p>⑤ 기타 전자적 지급 방법에 의한 대금 지급 등</p>
    </dd>

    <p className="mb-2">제 5 조 (수신확인 통지, 구매신청 변경 및 취소)</p>
    <dd className="col-12">
    <p>① 회사는 구매신청이 있는 경우 구매자에게 수신확인통지를 합니다.</p>
    <p>② 수신확인통지를 받은 구매자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있습니다.</p>
    <p>③ 구매신청 변경 및 취소에 관한 세부규정은 제 7조 청약철회 등에 관한 규정에 따릅니다.</p>
    </dd>

    <p className="mb-2">제 6 조 (재화, 서비스 등의 제공)</p>
    <dd className="col-12">
    <p>① 회사는 구매신청 이후 대금의 전부를 받은 경우에는 즉시 이용할 수 있도록 제공해야 합니다.</p>
    <p>② 회사가 기술적 또는 외적 변인으로 인해 공급시기를 자체 시 2 영업일 이내에 조치를 취합니다. 이때 회사는 구매자가 공급시기의 지체 사유와 진행 사항을 확인할 수 있도록 적절한 조치를 취합니다.</p>
    <p>③ 회사는 다음 각 조항에 해당하는 경우 유료 서비스 이용 신청에 대한 승인을 제한할 수 있고, 그 사유가 해소될 때까지 승인을 유보할 수 있습니다.</p>
    <p>가. 유료서비스의 이용요금을 납입하지 않은 경우</p>
    <p>나. 유료서비스 신청 금액 총액과 입금 금액 총액이 일치하지 않은 경우</p>
    <p>다. 기타 회사가 필요하다고 인정되는 경우</p>
    </dd>

    <p className="mb-2">제 7 조 (청약, 신청의 철회)</p>
    <dd className="col-12">
    <p>① 회사와 재화, 서비스 등의 이용에 관한 계약을 체결한 구매자는 수신확인의 통지를 받은 날로부터 7일 이내에는 청약 철회를 요청할 수 있습니다.</p>
    <p>② 구매자는 아래에 해당하는 경우에는 환불을 요구할 수 없습니다.</p>
    <p>가. 구매자의 개인 맞춤형 유료상품의 결과를 열람한 경우</p>
    <p>나. 구매자의 성적으로 지원 가능한 대학 정보를 검색하여 열람한 경우</p>
    <p>다. 구매자 구매정보를 공유하여 타 사용자가 이용한 경우</p>
    <p>③ 서비스 오류 등 회사의 과실이 인정되는 경우 환불이 가능합니다.</p>
    <p>가. 서비스 이용기간 중에 시스템 오류로 결과를 열람하지 못한 경우</p>
    <p>나. 다른 자료와 중복된 자료</p>
    <p>다. 기타 회사가 인정하는 자료</p>
    </dd>

    <p className="mb-2">제 8 조 (청약 철회 등의 효과)</p>
    <dd className="col-12">
    <p>① 회사는 구매자로부터 재화 등을 반환 받은 경우 10 영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다.</p>
    <p>② 회사는 청약 철회에 따른 대금을 환급함에 있어서 구매자가 신용카드 또는 전자화페 등의 결제수단으로 재화, 서비스 등의 대금을 지급한 때에는 지체없이 당해 결제수단을 제공한 사업자로 하여금 재화, 서비스 등의 대금의 청구를 정지 또는 취소하도록 요청할 수 있습니다</p>
    <p>③ 회사는 구매자에게 청약 철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 단, 재화 등의 내용이 표시 또는 광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 회사가 부담합니다.</p>
    <p>④ 청약 철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 구매자가 부담합니다.</p>
    </dd>

    <p className="mb-2">제 9 조 (환급)</p>
    <dd className="col-12">
    <p>
        회사는 구매자가 구매 신청한 재화 또는 서비스가 품절, 오류 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체없이 그 사유를 구매자에게 통지하여야 하며, 사전에 재화 등의 대금을 받은 경우에는 사유발생일로부터 10 영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.
    </p>
    </dd>
`;

const personalInfoPolicy = `
<p>
    <h6 className="mt-5 mb-3">제 1 장 이대로 개인정보 처리방침</h6>
    <p className="mb-2">
        “개인정보 처리방침”이란 이용자가 안심하고 서비스를 이용할 수 있도록 회사가 준수해야 할 지침을 의미하며, 이대로는 개인정보처리자가 준수하여야 하는 대한민국의 관계법령 및 개인정보보호 규정, 가이드라인을 준수하여 개인정보 처리방침을 제공합니다.
    </p>
    <dd className="col-12">
        <p>1. 개인정보</p>
        <p>가. 필수사항 : 이메일, 비밀번호</p>
        <p>나. 선택사항 : 생년월일, 성별, 전화번호(결제 시)</p>
        <p>2. 처리목적</p>
        <p>
            이대로에서 수집한 이용자 개인정보는 회원관리, 서비스 제공 및 개선, 신규 서비스 개발 등 이대로 서비스 제공을 위해 필요한 목적으로만 이용합니다. 개인정보 수집 시 반드시 이용자에게 처리 목적을 고지하여 동의를 받고 있습니다.
        </p>
        <p>3. 제3자 제공</p>
        <p>
            제3자의 서비스 연결 및 제휴사 서비스 이용을 위해 개인정보가 제공될 수 있습니다. 이대로는 법령에 규정된 경우를 제외하고는 이용자의 별도 동의 없이 제3자에게 개인정보를 제공하지 않습니다. 외부 제휴사 등의 서비스 이용 시 이용자에게 제3자 제공 동의를 받은 후 개인정보를 제공하고 있습니다. 개인정보가 제공될 수 있는 제3자 업체 목록은 다음과 같으며, 제3자 제공 발생 시 이용자에게 제공받는 자, 제공목적 등을 고지하여 동의를 받은 후 제공합니다.
        </p>
        <p>가. 제 3자 제공 업체</p>
        <p>- 제공받는자 : ㈜뉴턴, ㈜카카오, 세종네트웍스(주)</p>
        <p>- 제공목적 : SMS, LMS, MMS, 알림톡, 친구톡T, 친구톡I, 카카오톡 등</p>
        <p>- 제공정보 : 이용자의 전화번호</p>
        <p>4. 처리위탁</p>
        <p>
            서비스 제공을 위해 필요한 경우 개인정보 처리 업무 중 일부를 외부에 위탁할 수 있습니다. 위탁받은 업체가 위탁받은 업무 목적 외로 개인정보를 처리하는 것을 제한하고, 기술적/관리적 보호조치 적용 및 재위탁 제한 등 위탁업체의 개인정보 보호 관련 법령 준수 여부를 관리/감독하고 있습니다.
        </p>
        <p>5. 정보주체의 권리의무</p>
        <p>
            이대로는 개인정보 처리에 대한 정보주체의 권리 의무를 보장합니다. 이대로 이용자는 언제든지 본인의 개인정보를 조회하거나 수정할 수 있으며, 수집/이용, 제공에 대한 동의를 철회하거나 가입 해지를 요청할 수 있습니다.
        </p>
        <p>6. 고충처리</p>
        <p>
            이대로 서비스 이용 중 발생하는 개인정보보호 관련 문의, 불만, 조언이나 기타 사항은 이대로의 개인정보보호책임자 및 담당부서로 연락주시면 확인하여 답변드리고 있습니다. 이대로는 항상 사용자의 목소리에 귀기울이고 더 안전한 서비스를 제공하기 위해 노력하고 있습니다.
        </p>
    </dd>

    <h6 className="mt-5 mb-3">제 2 장 개인정보 수집</h6>
    <p className="mb-2">1. 서비스 제공을 위한 필요 최소한의 개인정보를 수집합니다.</p>
    <dd className="col-12">
        <p>
            회원 가입 시 또는 서비스 이용 과정에서 홈페이지 또는 어플리케이션이나 프로그램 등을 통해 서비스 제공을 위해 필요 최소한의 개인정보를 수집하고 있습니다. 서비스 제공을 위해 반드시 필요한 최소한의 정보를 필수항목으로, 그 외 특화된 서비스를 제공하기 위해 추가 수집하는 정보는 선택항목으로 동의를 받고 있으며, 선택항목에 동의하지 않은 경우에도 서비스 이용 제한은 없습니다.
        </p>
        <p>
            가. 회원가입 시에는 이메일, 비밀번호는 필수입력 사항이며, 상품 결제 시 생년월일, 성별, 전화번호는 선택입력 정보입니다.
        </p>
        <p>
            나. 본인인증 시에는 이름, 성별, 생년월일, 휴대전화번호, 통신사업자, 내/외국인 여부, 암호화된 이용자 확인값(CI), 중복가입확인정보(DI)가 필요합니다.
        </p>
        <p>
            다. 법정대리인 동의 시에는 법정대리인 정보(이름, 성별, 생년월일, 휴대전화번호, 통신사업자, 내/외국인 여부, 암호화된 이용자 확인값(CI), 중복가입확인정보(DI))가 필요합니다.
        </p>
        <p>
            라. 유료서비스 이용 시:
            <br />
            - 신용카드 결제 시: 카드번호(일부), 카드사명 등
            <br />
            - 휴대전화번호 결제 시: 휴대전화번호, 결제승인번호 등
            <br />
            - 계좌이체 시: 예금주명, 계좌번호, 계좌은행 등
            <br />- 상품권 이용 시: 상품권 번호, 해당 사이트 아이디
        </p>
        <p>마. 환불처리 시에는 계좌은행, 계좌번호, 예금주명, 이메일의 정보가 필요합니다.</p>
        <p>바. 현금영수증 발행 시에는 휴대전화번호, 현금영수증 카드번호의 정보가 필요합니다.</p>
        <p>
            사. 고객상담 시에는 고객담당 부서로 문의 및 상담 처리를 위한 추가적인 정보를 수집할 수 있습니다.
        </p>
    </dd>
    <p className="mb-2">
        2. 일부 서비스에서는 특화된 여러 기능을 제공하기 위해 이용자에게 동의를 받고 추가적인 개인정보를 수집할 수 있습니다.
    </p>
    <p className="mb-2">
        3. 개인정보를 수집하는 경우에는 원칙적으로 사전에 이용자에게 해당사실을 알리고 동의를 구하고 있으며, 아래와 같은 방법을 통해 개인정보를 수집합니다.
    </p>
    <dd className="col-12">
        <p>가. 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우</p>
        <p>나. 제휴 서비스 또는 단체 등으로부터 개인정보를 제공받은 경우</p>
        <p>다. 고객센터를 통한 상담 과정에서 웹페이지, 메일, 팩스, 전화 등</p>
        <p>라. 온·오프라인에서 진행되는 이벤트/행사 등 참여</p>
    </dd>
    <p className="mb-2">4. 서비스 이용 과정에서 단말기정보, IP 주소, 쿠키, 서비스 이용내역 등의 정보가 자동으로 생성되어 수집될 수 있습니다.</p>
    <p className="mb-2">5. 서비스 간 제휴, 연계 등으로 제 3 자로부터 개인정보를 제공받습니다.</p>

    <h6 className="mt-5 mb-3">제 3 장 개인정보 이용</h6>
    <p className="mb-2">1. 회원관리, 서비스 제공·개선, 신규서비스 개발 등을 위해 이용합니다.</p>
    <dd className="col-12">
        <p>가. 회원 식별 및 가입의사 확인, 본인/연령 확인</p>
        <p>나. 14세 미만 아동의 개인정보 수집 시 법정 대리인 동의여부 확인, 법정 대리인 권리행사 시 본인 확인</p>
        <p>다. 문의사항 또는 불만처리, 공지사항 전달</p>
        <p>라. 유료서비스 이용 시 콘텐츠 등의 전송이나 배송·요금 정산</p>
        <p>마. 서비스의 원활한 운영에 지장을 주는 행위(계정 도용 및 부정 이용 행위 등 포함)에 대한 방지 및 제재</p>
        <p>바. 음성명령 처리 및 음성인식 향상</p>
        <p>사. 신규 서비스 개발 및 서비스 기능 개선, 개인화된 서비스 제공, 프라이버시 보호를 위한 서비스 환경 구축</p>
        <p>아. 서비스 이용 기록, 접속 빈도 및 서비스 이용에 대한 통계</p>
    </dd>
    <p className="mb-2">2. 개인정보의 추가적인 이용·제공을 하는 경우가 있습니다.</p>
    <dd className="col-12">
        <p>
            수집 목적과 합리적으로 관련된 범위에서는 법령에 따라 이용자의 동의 없이 개인정보를 이용하거나 제3자에게 제공할 수 있습니다. 이때 ‘당초 수집 목적과 관련성이 있는지, 수집한 정황 또는 처리 관행에 비추어 볼 때 개인정보의 추가적인 이용 또는 제공에 대한 예측 가능성이 있는지, 이용자의 이익을 부당하게 침해하는지, 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지’를 종합적으로 고려합니다.
        </p>
        <p>
            이대로는 수집한 개인정보를 특정 개인을 알아볼 수 없도록 가명 처리하여 통계작성, 과학적 연구, 공익적 기록보존 등을 위하여 처리할 수 있습니다. 이때 가명정보는 재식별되지 않도록 추가정보와 분리하여 별도 저장·관리하고 필요한 기술적·관리적 보호조치를 취합니다.
        </p>
    </dd>

    <h6 className="mt-5 mb-3">제 4 장 개인정보 제공</h6>
    <p className="mb-2">1. 이대로는 이용자의 사전 동의 없이 개인정보를 제3자에게 제공하지 않습니다.</p>
    <dd className="col-12">
        <p>
            다만, 이용자가 이대로 계정 로그인 서비스를 이용하거나 외부 제휴사 등의 서비스를 이용하는 경우 필요한 범위 내에서 이용자의 동의를 얻은 후에 개인정보를 제3자에게 제공하고 있습니다. 또한, 해외 서비스 이용을 위해 이용자의 동의를 받아 국외로 개인정보가 제공될 수 있으며, 국외 이전 시 제공받는 자 및 이전국가 등에 대해 고지하여 별도 동의를 받고 있습니다. 이대로는 재난, 감염병, 급박한 생명·신체 위험을 초래하는 사건사고, 급박한 재산 손실 등의 긴급상황이 발생하는 경우 정보주체의 동의 없이 관계기관에 개인정보를 제공할 수 있습니다.
        </p>
        <p>2. 서비스 제공을 위해 필요한 경우 개인정보 처리 업무 중 일부를 외부에 위탁할 수 있습니다.</p>
        <p>
            위탁받은 업체가 위탁받은 업무 목적 외로 개인정보를 처리하는 것을 제한하고, 기술적·관리적 보호조치 적용 및 재위탁 제한 등 위탁받은 업체의 개인정보 보호 관련 법령 준수 여부를 관리·감독하고 있습니다. 이용자는 고객센터를 통해 개인정보의 국외 이전을 거부할 수 있습니다. 이용자가 개인정보의 국외 이전을 거부하는 경우, 개인정보 국외이전이 필수적으로 수반되는 서비스의 이용이 제한될 수 있습니다.
        </p>
    </dd>

    <h6 className="mt-5 mb-3">제 5 장 개인정보 파기</h6>
    <p className="mb-2">1. 수집 및 이용 목적의 달성 또는 회원 탈퇴 등 파기 사유가 발생한 경우 개인정보의 형태를 고려하여 파기방법을 정합니다.</p>
    <dd className="col-12">
        <p>전자적 파일 형태인 경우 복구 및 재생되지 않도록 안전하게 삭제하고, 그 밖에 기록물, 인쇄물, 서면 등의 경우 분쇄하거나 소각하여 파기합니다.</p>
        <p>2. 내부 방침에 따라 일정 기간 보관 후 파기하는 정보는 아래와 같습니다.</p>
        <p>가. 아래 정보는 탈퇴일부터 최대 1 년간 보관 후 파기합니다.</p>
        <p>- 안내메일 발송 및 CS 문의 대응을 위해 계정과 탈퇴안내 이메일 주소를 암호화하여 보관</p>
        <p>- 서비스 부정이용 기록</p>
        <p>나. 권리침해 신고 및 유해정보 신고 이력은 5 년간 보관 후 파기합니다.</p>
        <p>- 카드영수증: 카드영수증 서비스 탈퇴일부터 1 년 보관 후 파기합니다.</p>
        <p>- 암호화된 이용자 확인값(CI), 카드영수증 항목 및 상세내역</p>
        <p>3. 이대로는 운영정책에 따라 1 년간 서비스를 이용하지 않은 이용자의 개인정보를 별도로 분리 보관 또는 삭제하고 있으며, 분리 보관된 개인정보는 4 년간 보관 후 지체없이 파기합니다.</p>
    </dd>

    <h6 className="mt-5 mb-3">제 6 장 이용자 및 법정대리인의 권리와 행사 방법</h6>
    <p className="mb-2">1. 이용자는 자신의 개인정보 처리에 관하여 아래와 같은 권리를 가질 수 있습니다.</p>
    <dd className="col-12">
        <p>가. 개인정보 열람(조회)을 요구할 권리</p>
        <p>나. 개인정보 정정을 요구할 권리</p>
        <p>다. 개인정보 처리정지를 요구할 권리</p>
        <p>라. 개인정보 삭제요구 및 동의철회/탈퇴를 요구할 권리</p>
        <p>
            2. 이대로는 이용자의 요청을 받은 경우 이를 지체없이 처리하며, 이용자가 개인정보의 오류에 대한 정정을 요청한 경우 정정을 완료하기 전까지 해당 개인정보를 이용 또는 제공하지 않습니다.
        </p>
        <p>3. 14세 미만 아동의 개인정보를 처리할 경우에는 법정대리인의 동의를 받아야 합니다.</p>
        <p>
            법정대리인은 아동의 개인정보를 조회하거나 수정 및 삭제, 처리정지 및 동의 철회 등의 권리를 행사할 수 있습니다. 법정대리인의 동의를 받기 위하여 아동에게 법정대리인의 서명, 연락처와 같이 최소한의 정보를 요구할 수 있으며, 아래 방법으로 법정대리인의 동의를 확인합니다.
        </p>
        <p>가. 법정대리인의 휴대전화 본인인증을 통해 본인여부를 확인하는 방법</p>
        <p>나. 법정대리인에게 동의내용이 적힌 서면을 제공하여 서명날인 후 제출하게 하는 방법</p>
        <p>다. 그 밖에 위와 준하는 방법으로 법정대리인에게 동의내용을 알리고 동의의 의사표시를 확인하는 방법</p>
    </dd>

    <h6 className="mt-5 mb-3">제 7 장 개인정보 자동 수집 장치에 관한 사항(설치·운영 및 거부)</h6>
    <p className="mb-2">1. 웹기반 서비스 제공을 위하여 쿠키를 설치·운영할 수 있습니다.</p>
    <dd className="col-12">
        <p>빠르고 편리한 웹사이트 사용을 지원하고 맞춤형 서비스를 제공하기 위해 쿠키를 사용합니다.</p>
        <p>2. 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 쿠키를 사용합니다.</p>
        <p>
            이용자가 웹사이트에 방문할 경우 웹 사이트 서버는 이용자의 디바이스에 저장되어 있는 쿠키를 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공하게 됩니다. 쿠키는 이용자가 웹 사이트를 방문할 때, 웹 사이트 사용을 설정한 대로 접속하고 편리하게 사용할 수 있도록 돕습니다. 또한, 이용자의 웹사이트 방문 기록, 이용 형태를 통해서 최적화된 광고 등 맞춤형 정보를 제공하기 위해 활용됩니다.
        </p>
        <p>
            3. 이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 웹브라우저 상단의 ‘설정 &gt; 개인정보보호 &gt; 쿠키 및 기타 사이트 데이터’ 경로에서 쿠키 설정을 통해 쿠키 허용 및 거부를 할 수 있습니다. 다만, 쿠키 설치를 거부할 경우 웹 사용이 불편해지며, 로그인이 필요한 일부 서비스 이용이 어려울 수 있습니다.
        </p>
    </dd>

    <h6 className="mt-5 mb-3">제 8 장 개인정보 안정성 확보 조치에 관한 사항</h6>
    <p className="mb-2">이대로는 이용자의 개인정보 보호를 위해 아래의 노력을 합니다.</p>
    <dd className="col-12">
        <p>1. 이용자의 개인정보를 암호화하고 있습니다.</p>
        <p>2. 해킹이나 컴퓨터 바이러스로부터 보호하기 위하여 노력하고 있습니다.</p>
        <p>3. 개인정보에 접근할 수 있는 사람을 최소화하고 있습니다.</p>
        <p>4. 개인정보 취급자에게 이용자의 개인정보 보호에 대해 정기적인 교육을 실시하고 있습니다.</p>
        <p>5. 이용자 정보의 보호 활동 및 체계에 대해 국내 인증 규격을 충족하고 있습니다.</p>
    </dd>

    <h6 className="mt-5 mb-3">제 9 장 개인정보 보호책임자 및 고충처리 부서</h6>
    <p className="mb-2">이대로는 이용자의 개인정보 관련 문의사항 및 불만처리 등을 위하여 개인정보 보호책임자 및 고충처리 부서를 운영하고 있습니다.</p>
    <dd className="col-12">
        <p>1. 책임자 : 김선국(개인정보 보호책임자/대표이사)</p>
        <p>2. 소속 : 개인정보보호부서</p>
        <p>3. 문의 : ceo@edaero.kr</p>
    </dd>
</p>
`;

const privacies = {
	privacies: {
		subInfo: "이용약관, 개인정보처리방침, 마케팅수신동의",
		title: "EDAERO",
		termsOfServiceLabel: "이용약관",
		termsOfService,
		privacyPolicyLabel: "개인정보처리방침",
		privacyPolicy,
		marketingAgreementLabel: "광고및마케팅수신동의",
		marketingAgreement,
		refundPoicyLabel: "환불정책",
		refundPoicy,
		personalInfoLabel: "개인정보처리방침",
		personalInfoPolicy,
	},
};

export default privacies;
