const termsOfService = `
    <h5 className="mt-5 mb-1">Terms of Service</h5>
    <p className="mb-3 size-13">Updated at 2023-05-01</p>
    <p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin malesuada condimentum libero nec egestas. Maecenas velit est, placerat ut molestie vitae, tincidunt eu ex. In imperdiet quis neque non molestie. Sed efficitur velit nunc, a dapibus eros hendrerit non. Nulla laoreet quam at libero varius, ac laoreet massa sodales. Aenean quis turpis diam. Sed eget ante dolor. Donec bibendum faucibus faucibus.</p>
    <p className="mb-2">Duis pretium nisl eget nulla rutrum, sed congue velit suscipit. Ut eget mi nisl. Integer eu rutrum arcu. Integer id orci et quam varius sodales sed ac felis. Quisque id urna at leo lacinia faucibus eget vitae velit. Donec ut quam vel felis iaculis blandit id in mauris. Morbi mattis bibendum nisl in pulvinar. Curabitur hendrerit magna dui, sit amet tristique ipsum sollicitudin non. Donec lorem justo, pretium vel rutrum id, dignissim in elit. Mauris ultrices vel ipsum in viverra. Donec imperdiet ex eget massa malesuada convallis. Fusce ac euismod turpis. Nunc ultricies magna vel orci faucibus accumsan. Quisque quis tempus lectus, eu aliquet sapien. Phasellus leo urna, euismod eu erat sed, dapibus efficitur turpis.</p>
    <p className="mb-2">Morbi a ipsum justo. In faucibus scelerisque nibh at dictum. Phasellus id est eu ex congue egestas id ut justo. Donec vitae vehicula urna. Donec in facilisis mi. Phasellus nibh mauris, tincidunt in libero non, dictum rutrum libero. Sed vestibulum sapien et tellus placerat dignissim. Vestibulum ex lorem, tempor et ultrices in, convallis a augue. Phasellus a dolor at sem feugiat imperdiet ut et diam. Fusce sed ligula viverra, pretium risus eget, elementum nunc. Fusce pellentesque in magna in placerat. Nulla tincidunt tellus vitae felis molestie mollis.</p>
    <p className="mb-2">Praesent a dolor efficitur, dictum metus eget, rhoncus augue. Mauris congue vel nisi et feugiat. Suspendisse molestie diam congue, faucibus tortor a, blandit felis. Donec porta cursus nunc, efficitur pharetra libero vulputate nec. Sed viverra ligula a turpis viverra volutpat. Sed porttitor justo non metus malesuada cursus. Pellentesque sagittis, enim et semper interdum, orci est bibendum metus, nec suscipit nibh nisl a ipsum. Etiam mattis, turpis mollis scelerisque ultricies, felis diam molestie lacus, nec consequat ante leo quis augue. In mollis mauris vel libero pulvinar, porttitor facilisis ipsum posuere. Proin tellus orci, eleifend non urna consequat, semper vulputate nisi.</p>
    <p className="mb-2">Nunc dignissim porttitor ullamcorper. Sed ut ante dui. Mauris lobortis vehicula velit vitae pretium. Quisque at ante arcu. Duis aliquam scelerisque augue eleifend congue. Nullam porttitor consectetur elit in posuere. Vivamus dolor massa, egestas ac consectetur in, pellentesque et metus. Suspendisse consectetur libero elit, euismod elementum lacus gravida nec.</p>
    <p className="mb-0">Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Suspendisse at leo pulvinar erat sollicitudin maximus. Suspendisse aliquet vel dui quis bibendum. Nullam velit orci, vestibulum eleifend rhoncus vitae, lobortis id felis. Curabitur molestie iaculis ornare. In mollis enim ex, nec lobortis justo faucibus euismod. Quisque dignissim dolor ligula, ac egestas sem pulvinar ultrices. Vivamus gravida purus eu nulla varius, nec efficitur libero facilisis. Donec vitae pellentesque sem, eget sodales magna. Curabitur ullamcorper nunc odio, et lobortis est semper id. Mauris mollis tempor justo molestie dignissim. Nullam dolor velit, varius vitae odio at, bibendum lacinia eros.</p>
`;

const privacyPolicy = `
    <h5 className="mt-5 mb-1">Privacy Policy</h5>
    <p className="mb-3 size-13">Updated at 2023-05-01</p>
    <p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin malesuada condimentum libero nec egestas. Maecenas velit est, placerat ut molestie vitae, tincidunt eu ex. In imperdiet quis neque non molestie. Sed efficitur velit nunc, a dapibus eros hendrerit non. Nulla laoreet quam at libero varius, ac laoreet massa sodales. Aenean quis turpis diam. Sed eget ante dolor. Donec bibendum faucibus faucibus.</p>
    <p className="mb-2">Duis pretium nisl eget nulla rutrum, sed congue velit suscipit. Ut eget mi nisl. Integer eu rutrum arcu. Integer id orci et quam varius sodales sed ac felis. Quisque id urna at leo lacinia faucibus eget vitae velit. Donec ut quam vel felis iaculis blandit id in mauris. Morbi mattis bibendum nisl in pulvinar. Curabitur hendrerit magna dui, sit amet tristique ipsum sollicitudin non. Donec lorem justo, pretium vel rutrum id, dignissim in elit. Mauris ultrices vel ipsum in viverra. Donec imperdiet ex eget massa malesuada convallis. Fusce ac euismod turpis. Nunc ultricies magna vel orci faucibus accumsan. Quisque quis tempus lectus, eu aliquet sapien. Phasellus leo urna, euismod eu erat sed, dapibus efficitur turpis.</p>
    <p className="mb-2">Morbi a ipsum justo. In faucibus scelerisque nibh at dictum. Phasellus id est eu ex congue egestas id ut justo. Donec vitae vehicula urna. Donec in facilisis mi. Phasellus nibh mauris, tincidunt in libero non, dictum rutrum libero. Sed vestibulum sapien et tellus placerat dignissim. Vestibulum ex lorem, tempor et ultrices in, convallis a augue. Phasellus a dolor at sem feugiat imperdiet ut et diam. Fusce sed ligula viverra, pretium risus eget, elementum nunc. Fusce pellentesque in magna in placerat. Nulla tincidunt tellus vitae felis molestie mollis.</p>
    <p className="mb-2">Praesent a dolor efficitur, dictum metus eget, rhoncus augue. Mauris congue vel nisi et feugiat. Suspendisse molestie diam congue, faucibus tortor a, blandit felis. Donec porta cursus nunc, efficitur pharetra libero vulputate nec. Sed viverra ligula a turpis viverra volutpat. Sed porttitor justo non metus malesuada cursus. Pellentesque sagittis, enim et semper interdum, orci est bibendum metus, nec suscipit nibh nisl a ipsum. Etiam mattis, turpis mollis scelerisque ultricies, felis diam molestie lacus, nec consequat ante leo quis augue. In mollis mauris vel libero pulvinar, porttitor facilisis ipsum posuere. Proin tellus orci, eleifend non urna consequat, semper vulputate nisi.</p>
    <p className="mb-2">Nunc dignissim porttitor ullamcorper. Sed ut ante dui. Mauris lobortis vehicula velit vitae pretium. Quisque at ante arcu. Duis aliquam scelerisque augue eleifend congue. Nullam porttitor consectetur elit in posuere. Vivamus dolor massa, egestas ac consectetur in, pellentesque et metus. Suspendisse consectetur libero elit, euismod elementum lacus gravida nec.</p>
    <p className="mb-0">Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Suspendisse at leo pulvinar erat sollicitudin maximus. Suspendisse aliquet vel dui quis bibendum. Nullam velit orci, vestibulum eleifend rhoncus vitae, lobortis id felis. Curabitur molestie iaculis ornare. In mollis enim ex, nec lobortis justo faucibus euismod. Quisque dignissim dolor ligula, ac egestas sem pulvinar ultrices. Vivamus gravida purus eu nulla varius, nec efficitur libero facilisis. Donec vitae pellentesque sem, eget sodales magna. Curabitur ullamcorper nunc odio, et lobortis est semper id. Mauris mollis tempor justo molestie dignissim. Nullam dolor velit, varius vitae odio at, bibendum lacinia eros.</p>
`;

const marketingAgreement = `
    <h5 className="mt-5 mb-3">Marketing Agreement</h5>
    <p className="mb-2">Receive service news and marketing information provided by the service through app push, chat, e-mail, etc. included in the “As it is” application service of Idaero Co., Ltd. (hereinafter referred to as the ‘Company’).</p>
    <p className="mb-2">App push and e-mail of the “as is” application are channels that provide useful information such as news, benefits, and coupons for integrated services directly operated by the company.</p>
    <p className="mb-2">If you do not want to receive marketing information from the application “as is”, you can always unsubscribe from My Page &gt Settings &gt Notifications.</p>
    <p className="mb-2">Purpose Information on user information (e-mail, etc.) and behavioral information collected based on &apos;cookies&apos; or &apos;advertising identifier&apos; for marketing purposes, such as information on new services (products), provision of opportunities to participate in events, identification of access frequency, or statistics on service use by members and service and marketing information will be sent through channels such as email, app push, etc. In addition to providing existing services such as content (including advertisements), personal information is used to discover new service (product) elements and improve existing services, such as analyzing service visits and usage records, and providing opportunities to participate in events based on personal information and interests. to use.</p>
    <p className="mb-0">You can use the service of the “as is” application even when you withdraw your consent, withdraw from membership, or refuse to agree to this content. However, you cannot receive information useful for the use of the “as is” application, such as new service (product) information and event participation information of the “as is” application.</p>
`;

const privacies = {
    privacies: {
        subInfo: 'Terms of service, privacy policy & marketing agreement',
        title: 'EDAERO',
        termsOfServiceLabel: 'Terms of Service',
        termsOfService,
        privacyPolicyLabel: 'Privacy Policy',
        privacyPolicy,
        marketingAgreementLabel: 'Marketing Agreement',
        marketingAgreement,
    }
};

export default privacies;
