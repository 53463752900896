import i18next from "i18next";
import en from "../locales/en.json";
import ko from "../locales/ko.json";
import privaciesEn from "../locales/privacies/en";
import privaciesKo from "../locales/privacies/ko";
import { useEffect } from "react";

export default function useLocale() {
    useEffect(() => {
        i18next
         .init({
            lng: 'ko',
            fallbackLng: 'en',
            compatibilityJSON: 'v3',
            resources: {
                en: {
                    translation: {
                        ...en,
                        ...privaciesEn,
                    }
                },
                ko: {
                    translation: {
                        ...ko,
                        ...privaciesKo,
                    }
                },
            }
        });
    }, []);
}
