import React, { useEffect } from "react";
import ReactDom from "react-dom";
import "/public/css/unicons.css";
import "/public/css/swiper.min.css";
import "/public/css/style.css";
import "/public/css/xeicon.min.css";
import { useRouter } from "next/router";
import { AuthUserProvider } from "../contexts/AuthUserContext";
import useLocale from "../hooks/useLocale";
import useScript from "../hooks/useScript";
import { registerLicense } from "@syncfusion/ej2-base";
import { SessionProvider } from "next-auth/react";
import jQuery from "jquery";
import { QueryClient, QueryClientProvider } from "react-query";

const MyApp = ({ Component, pageProps: { session, ...pageProps } }) => {
	const router = useRouter();

	// Create a QueryClient instance
	const queryClient = new QueryClient();

	useEffect(() => {
		if (typeof window !== undefined) {
			if (typeof window.jQuery !== undefined) {
				window.$ = window.jQuery = jQuery;

				require("/public/js/bootstrap.min.js");
			}

			if (typeof document !== undefined) {
				document.body.classList.add("hero-anime");
			}
		}
	}, [router]);

	useLocale();

	useScript("/js/plugins.js");
	useScript("/js/parallax.js");
	useScript("/js/custom.js");

	// Registering Syncfusion license key
	registerLicense(process.env.NEXT_PUBLIC_SYNCFUSION_LICENSE_KEY);

	return (
		<SessionProvider session={session}>
			<QueryClientProvider client={queryClient}>
				<AuthUserProvider>
					<Component {...pageProps} />
				</AuthUserProvider>
			</QueryClientProvider>
		</SessionProvider>
	);
};

export default MyApp;
